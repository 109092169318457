body {
    background-image: url('../public/backgroundWebsite2.webp');
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
    background-attachment:fixed;
    min-height: 100vh;
    
}

.glass-pane {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.6);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.4px);
    -webkit-backdrop-filter: blur(9.4px);
    border: 1px solid rgba(255, 255, 255, 0.29);
}

.glass-pane-header{
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.4px);
    -webkit-backdrop-filter: blur(9.4px);
    border: 1px solid rgba(255, 255, 255, 0.29);
}

.white-text{
    color:rgb(54, 54, 54);
    text-align: center;
}

.container{
    margin-left: 10.5%;
    margin-right: 10.5%;
    margin-bottom: 100px;
    color: rgb(54, 54, 54)
}

.paragraph-center{
    text-align: center;
}

.certification-margin{
    margin-right: 50px;
}